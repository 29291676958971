import React from 'react';
import { Button, colors } from '@insurancemenu/imenu-components-lib';
import { Link, NavLink } from 'react-router-dom';
import { Home } from '@insurancemenu/imenu-components-lib/dist/icons';
import IMLogo from '../../../assets/images/general/insuranceMenuLogo.svg';
import { createStyles } from './styles';
import { ImageWrapper } from '../ImageWrapper';
import MobileDropdown from './MobileDropdown';

export const LINKS = Object.freeze({
  SCHEDULE_DEMO: 'https://calendar.app.google/mEqvBY9VcLVEvceX6',
  SUPPORT: 'https://insurancemenu.zendesk.com/hc/en-us/requests/new?ticket_form_id=9675225892500',
  LOGIN: `https://${process.env.REACT_APP_AUTH_FE_BASE_URL}/login`,
  HELP_CENTER: 'https://insurancemenu.zendesk.com/hc/en-us',
});

export interface HeaderProps {
  showMenu?: boolean;
}

const Header = ({ showMenu = true }: HeaderProps): JSX.Element => {
  const classes = createStyles();

  return (
    <header className={classes.header}>
      <div className={classes.desktopHeaderWrapper}>
        <Link to="/home">
          <ImageWrapper imageURL={IMLogo} imageAlt="InsuranceMenu logo" className={classes.imageWrapper} />
        </Link>
        {showMenu && (
          <>
            <ul className={classes.links}>
              <li>
                <NavLink to="/home" activeClassName={classes.activeLink} className={classes.link}>
                  Home
                </NavLink>
              </li>
              <NavLink to="/about-us" activeClassName={classes.activeLink} className={classes.link}>
                About Us
              </NavLink>
              <NavLink
                to={{ pathname: LINKS.HELP_CENTER }}
                target="_blank"
                activeClassName={classes.activeLink}
                className={classes.link}
              >
                Help Center
              </NavLink>
              <NavLink to="/contacts" activeClassName={classes.activeLink} className={classes.link}>
                Contacts
              </NavLink>
            </ul>
            <div className={classes.headerButtons}>
              <Button
                color="custom"
                customColor={colors.green[600]}
                onClick={(): void => {
                  window.open(LINKS.SCHEDULE_DEMO, '_blank');
                }}
              >
                Schedule a demo
              </Button>
              <Button
                color="primary"
                onClick={(): void => {
                  window.open(LINKS.LOGIN, '_blank');
                }}
              >
                Login
              </Button>
            </div>
          </>
        )}
      </div>
      <div className={classes.mobileHeaderWrapper}>
        {showMenu ? (
          <>
            <Link to="/home" className={classes.mobileHomeLink}>
              <Home width="3.2rem" height="3.2rem" color={colors.neutral[700]} />
            </Link>
            <div className={classes.mobileScreenLogoWrapper}>
              <img src={IMLogo} alt="InsuranceMenu logo" className={classes.mobileScreenLogo} />
            </div>
            <MobileDropdown />
          </>
        ) : (
          <Link to="/home">
            <ImageWrapper imageURL={IMLogo} imageAlt="InsuranceMenu logo" className={classes.imageWrapper} />
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
